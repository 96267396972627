// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

import { observer, Provider } from 'mobx-react';
import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

import { HomePage, AboutPage, BioPage, CareersPage, PublicistCareerPage, StrategistCareerPage, ContactPage } from './pages';
import Nav from './components/Nav';
import ViewStore from './ViewStore';

interface Props extends WithStyles<typeof styles> {
    viewStore: ViewStore;
}

const Site = observer(class Site extends Component<Props> {
    render() {
        const view = this.props.viewStore.currentView!;

        let content: any;
        switch (view.name) {
            case 'home':    content = <HomePage />;    break;
            case 'about':   content = <AboutPage />;   break;
            case 'bio':     content = <BioPage />;     break;
            case 'careers': content = <CareersPage />; break;
            case 'career': {
                switch (view.role) {
                case 'strategist':
                    content = <StrategistCareerPage />;
                    break;
                case 'publicist':
                    content = <PublicistCareerPage />;
                    break;
                default:
                    content = <CareersPage />;
                    break;
                }
                break;
            }
            case 'contact': content = <ContactPage />; break;
            default:        return null;
        }

        let backgroundColor: string;
        switch (view.name) {
            case 'home':    backgroundColor = '#010516'; break;
            case 'about':   backgroundColor = '#011101'; break;
            case 'bio':     backgroundColor = '#211917'; break;
            case 'careers': backgroundColor = '#071315'; break;
            case 'career':  backgroundColor = '#071315'; break;
            case 'contact': backgroundColor = '#141415'; break;
        }

        return (
            <HelmetProvider>
                <Helmet>
                    <title>{view.title}</title>
                    <style>{'body { transition: background-color 0.5s; background-color: ' + backgroundColor + '; }'}</style>
                </Helmet>
                <Provider viewStore={this.props.viewStore}>
                    <Nav />
                    {content}
                </Provider>
            </HelmetProvider>
        );
    }
});

const styles = (theme: Theme) => createStyles({
});

export default withStyles(styles)(Site);
