// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
//import ReactGA  from 'react-ga';

import Site from './Site';
import ViewStore from './ViewStore';

// TODO setup Google Analytics
/*
if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-32906590-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}
*/

ReactDOM.render(
    <React.StrictMode>
        <Site viewStore={new ViewStore(process.env.NODE_ENV)} />
    </React.StrictMode>,
    document.getElementById('root')
);
