// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

// Nav is the title bar at the top of the screen, with links to the pages/account, and a hamburger menu (on mobile.)

import { makeObservable, action, observable } from 'mobx';
import { observer, inject }      from 'mobx-react';
import { Component, MouseEvent } from 'react';

import {
    createStyles, withStyles, WithStyles, Theme
} from '@material-ui/core';

import ViewStore           from '../ViewStore';
import HamburgerMenu       from './HamburgerMenu';
import MenuOverlay         from './MenuOverlay';
import { Default, Mobile } from './Responsive';

interface Props extends WithStyles<typeof styles> {
    viewStore?: ViewStore;
}

const Nav = inject('viewStore')(observer(class Nav extends Component<Props> {
    scrolled: boolean;

    constructor(props: Props) {
        super(props);

        makeObservable(this, {
            scrolled: observable,
            onHomeClick: action,
            onAboutClick: action,
            onContactClick: action,
            onHamburgerClick: action,
            onScrollEvent: action
        });

        this.scrolled = false;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScrollEvent)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollEvent);
    }

    onScrollEvent = (e: any) => {
        this.scrolled = (window.scrollY > 40);
    }

    render() {
        const { classes } = this.props;

        const viewStore = this.props.viewStore!;
        
        const icon = (
            <div className={classes.iconBox}>
                <a href='/' onClick={this.onHomeClick}>
                    <img className={classes.icon} src='/images/ara_logo_white_title_only.png' alt='ARA logo' />
                </a>
            </div>
        );

        let links: any;
        if (viewStore.limited) {
            links = (
                <>
                    <div className={classes.item} onClick={this.onContactClick}>
                        <a href='/contact' className={this.itemTextClassName(classes, 'contact')}>Contact Us</a>
                    </div>
                </>
            );
        }
        else {
            links = (
                <>
                    <div className={classes.item} onClick={this.onAboutClick}>
                        <a href='/about' className={this.itemTextClassName(classes, 'about')}>About Us</a>
                    </div>
                    <div className={classes.item} onClick={this.onCareersClick}>
                        <a href='/careers' className={this.itemTextClassName(classes, 'careers')}>Careers</a>
                    </div>
                    <div className={classes.item} onClick={this.onContactClick}>
                        <a href='/contact' className={this.itemTextClassName(classes, 'contact')}>Contact Us</a>
                    </div>
                </>
            );
        }

        return (
            <nav className={classes.root}>
                <div className={this.scrolled ? classes.scrolled : classes.unscrolled}>
                    <Default>
                        <div className={classes.container}>
                            {icon}
                            <div className={classes.break} />
                            {links}
                        </div>
                    </Default>
                    <Mobile>
                        <div className={classes.container}>
                            {icon}
                            <div className={classes.menuIcon}>
                                <HamburgerMenu
                                    isOpen={viewStore.menuOpen}
                                    menuClicked={this.onHamburgerClick}
                                    width={22}
                                    height={16}
                                    strokeWidth={2}
                                    color='#fff'
                                    animationDuration={0.5}
                                    />
                            </div>
                            {viewStore.menuOpen ? <MenuOverlay /> : null}
                        </div>
                    </Mobile>
                </div>
            </nav>
        );
    }

    itemTextClassName(classes: any, name: string) {
        if (this.props.viewStore!.currentView?.name === name)
            return `${classes.itemText} ${classes.selectedItemText}`;
        else
            return classes.itemText;
    }

    onHomeClick = (event: MouseEvent) => {
        event.preventDefault();
        this.props.viewStore!.showHome();
        this.props.viewStore!.menuOpen = false;
    };

    onAboutClick = (event: MouseEvent) => {
        event.preventDefault();
        this.props.viewStore!.showAbout();
        this.props.viewStore!.menuOpen = false;
    };

    onCareersClick = (event: MouseEvent) => {
        event.preventDefault();
        this.props.viewStore!.showCareers();
        this.props.viewStore!.menuOpen = false;
    };

    onContactClick = (event: MouseEvent) => {
        event.preventDefault();
        this.props.viewStore!.showContact();
        this.props.viewStore!.menuOpen = false;
    };

    onHamburgerClick = () => this.props.viewStore!.menuOpen = !this.props.viewStore!.menuOpen;
}));

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'fixed',
        top: 0,
        width: '100%'
    },
    scrolled: {
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.0))'
    },
    unscrolled: {
    },
    container: {
        width: '95%',
        paddingTop: 20,
        paddingBottom: 40,
        '@media (max-height: 599px)': {
            padding: '8px 8px'
        },
        '@media (max-width: 767px)': {
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    iconBox: {
        zIndex: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 34,
        marginLeft: 8
    },
    icon: {
        marginTop: 2,
        height: 32
    },
    break: {
        flex: 4
    },
    item: {
        position: 'relative',
        flex: 1,
        zIndex: 5,
        paddingTop: 10,
        paddingBottom: 10,
        minWidth: 112,
        maxWidth: 120
    },
    itemText: {
        position: 'relative',
        border: '2px solid rgba(0, 0, 0, 0)',
        fontSize: 15,
        fontWeight: 500,
        marginLeft: 4,
        marginRight: 4,
        paddingTop: 6,
        paddingBottom: 2,
        letterSpacing: '0.4px',
        textShadow: '1px 1px 2px rgba(0, 0, 0, .35)',
        color: '#f5f5f7',
        textAlign: 'center',
        transition: '0.35s',
        '&:hover': {
            color: '#fff'
        },
        textTransform: 'uppercase',
        fontFamily: 'Questrial'
    },
    selectedItemText: {
        borderBottom: '1.5px solid rgba(255, 255, 255, 0.8)'
    },

    menuIcon: {
        position: 'absolute',
        right: 32,
        cursor: 'pointer',
        zIndex: 10,
        '@media (max-width: 300px)': {
            display: 'none'
        }
    }
});

export default withStyles(styles)(Nav);
