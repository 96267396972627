// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

import { observer }  from 'mobx-react';
import { Component } from 'react';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

//import Footer from '../components/Footer';

interface Props extends WithStyles<typeof styles> {
}

const StrategistCareerPage = observer(class StrategistCareerPage extends Component<Props> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;

        return <>
            <div className={classes.content}>
                <div className={classes.container}>
                    <div className={classes.heading}>
                        Join Our Team
                    </div>
                    <div className={classes.box}>
                        <p className={classes.role}>Social Media Strategist</p>

                        <p className={classes.subtitle}>Who We Are:</p>
                        <p className={classes.description}>
                            ARA is a premier full-service Digital Branding and Public Relations firm serving notable individuals, CEOs, and celebrities. Headquartered in NYC, we specialize in architecting digital brands as well as providing bespoke solutions to a broad spectrum of online issues and challenges. Our job is to shape our client’s digital legacy and protect them where it matters most. We are a small (but growing) team working to build a better, digital-first PR agency.
                        </p>

                        <p className={classes.subtitle}>What We're Looking For:</p>
                        <p className={classes.description}>
                            Ashley Rust Associates seeks an experienced Social Media Strategist to join our growing team of contractors. 
                        </p>
                        <p className={classes.description}>
                            This person will develop mini-campaigns to amplify a number of high-profile clients on their personal social media platforms, monitor social platforms for mentions and reputation management, and be our “go-to” freelancer to help execute the work our clients need. 
                        </p>
                        <p className={classes.description}>
                            This role is ideal for a seasoned social media strategist who is able to work independently and efficiently, and is open to collaborating with our strategy team. 
                        </p>
                        <p className={classes.description}>
                            This role is <b>not</b> a social media manager role where you are provided content to schedule into a calendar and/or write captions for. This is a much more strategy role focused on building a high-profile client’s personal brand–you will be doing the brainwork to craft creative content ideas to boost a client’s personal online presence.
                        </p>

                        <p className={classes.subtitle}><u>Knowledge and Skills</u></p>
                        <ul className={classes.list}>
                            <li>Experience writing content for individuals rather than consumer brands (bonus if you have experience working for people in tech, media, etc.)</li>
                            <li>Develop high-level social ideas for high-profile individuals to participate in (e.g. Reddit AMAs, Twitter spaces, Instagram live, etc.)</li>
                            <li>Create interesting content ideas to amplify a client’s brand (e.g. a Twitter thread)</li>
                            <li>Bonus points if you’re a subject matter expert in business, technology, AI</li>
                            <li>Evaluate the client’s current social media presence</li>
                            <li>Must monitor all social platforms to catch negative brand mentions of high-profile clients (Facebook, Instagram, LinkedIn, Twitter, TikTok, Reddit, etc.)</li>
                        </ul>

                        <p className={classes.subtitle}><u>What you'll need</u></p>
                        <ul className={classes.list}>
                            <li>Proven social media experience</li>
                            <li>Prior experience at a creative/ad agency is a plus</li>
                            <li>Excellent organization and planning skills</li>
                            <li>Desire to work independently, figure it out, and get results</li>
                            <li>Ability to manage time, multi-task and work quickly with efficiency and attention to detail while ensuring deadlines are met</li>
                        </ul>

                        <p className={classes.subtitle}>What's in it for you?</p>
                        <p className={classes.description}>
                            Get ready to make an impact on a small but growing team. We’re fully remote and work with various unexpected clients - which means no two days will be the same. You will be a key contributor and help us build processes as our client roster grows.
                        </p>
                        <p className={classes.description}>
                            If this posting speaks to you, send us an email with “Social Media Strategist” in the subject line, including your LinkedIn link to <a className={classes.emailAddress} href='mailto:info@ashleyrustassociates.com' target='_blank' rel='noopener noreferrer'>info@ashleyrustassociates.com</a>, along with a link to a sample social account you’ve managed.
                        </p>
                    </div>
                </div>
            </div>
            {/*<Footer />*/}
        </>;
    }
});

const styles = (theme: Theme) => createStyles({
    content: {
        paddingTop: 60,
        paddingBottom: 60,
        color: '#fff',
        height: '100vh',
        '@media (max-width: 767px)': {
            paddingTop: 40,
            paddingBottom: 40
        }
    },
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80%',
        maxWidth: 800,
        alignItems: 'center',
        '@media (max-width: 767px)': {
            width: '90%',
            paddingLeft: 0,
            paddingRight: 0
        }
    },

    heading: {
        fontSize: 31,
        fontWeight: 500,
        paddingBottom: 16,
        marginBottom: 40,
        marginTop: 60,
        letterSpacing: 4,
        lineHeight: 1.4,
        fontFamily: 'Questrial',
        textTransform: 'uppercase',
        textAlign: 'center'
    },

    box: {
        fontFamily: 'Questrial',
        paddingBottom: 80
    },
    link: {
        color: 'rgb(241, 42, 69)'
    },
    hairline: {
        marginTop: 32,
        height: 0.5,
        background: '#ccc'
    },
    role: {
        fontSize: 21,
        fontWeight: 600,
        marginTop: 48,
        marginBottom: 16,
        letterSpacing: 2,
        textTransform: 'uppercase'
    },
    subtitle: {
        fontSize: 19,
        fontWeight: 600,
        marginBottom: 0
    },
    description: {
        marginTop: 6,
        marginBottom: 12,
        fontSize: 19,
        lineHeight: 1.4,
        color: '#ccc'
    },
    list: {
        paddingLeft: 28,
        '& li': {
            lineHeight: 1.5,
            fontSize: 19
        }
    },
    label: { 
        fontSize: 19,
        marginRight: 3,
        lineHeight: 1.5,
        color: '#fff'
    },
    emailAddress: {
        fontSize: 19,
        color: '#fff',
        letterSpacing: 1.1,
        textDecoration: 'none',
        padding: 1,
        borderBottom: '1.5px solid rgba(255, 255, 255, 0.2)',
        '&:hover': {
            textDecoration: 'none',
            borderBottom: '1.5px solid rgba(255, 255, 255, 0.35)'
        }
    }
});

export default withStyles(styles)(StrategistCareerPage);
