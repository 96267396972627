// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

import { inject, observer } from 'mobx-react';
import { Component, MouseEvent } from 'react';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

//import Footer from '../components/Footer';
import ViewStore from '../ViewStore';

interface Props extends WithStyles<typeof styles> {
    viewStore?: ViewStore;
}

const HomePage = inject('viewStore')(observer(class HomePage extends Component<Props> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;

        return <>
            <div className={classes.content}>
                <div className={classes.container}>
                    <div className={classes.description}>
                        <div className={classes.logoContainer}>
                            <img className={classes.logo} src='/images/ara_logo_white.png' alt='ARA logo' />
                        </div>
                        {/*
                        <div className={classes.tagLine}>
                            What will be your legacy?
                        </div>
                        */}
                        <div className={classes.subtitle}>
                            <p>ARA is a premier, full-service public relations, digital branding, and online 
                            reputation management firm serving notable individuals, CEOs, and celebrities.</p>

                            <p>We specialize in architecting digital brands as well as providing 
                            bespoke solutions to a broad spectrum of online issues and challenges.</p>

                            <p>If you would like more information on how we can help, please <a className={classes.link} href='/contact' onClick={this.onContactClick}>contact us</a>.</p>
                        </div>
                        <div className={classes.locations}>
                            <a className={classes.locationsLink} href='/contact' onClick={this.onContactClick}>
                                <span className={classes.location}>New York</span> • <span className={classes.location}>Lake Tahoe</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/*
            <Footer />
            */}
        </>;
    }

    onContactClick = (event: MouseEvent) => {
        event.preventDefault();
        this.props.viewStore!.showContact();
    };
}));

const styles = (theme: Theme) => createStyles({
    content: {
        height: '100vh',
        paddingTop: 120,
        paddingBottom: 100,
        '@media (max-width: 767px)': {
            paddingTop: 100,
            paddingBottom: 40
        }
    },
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80%',
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 767px)': {
            alignItems: 'center',
            width: '90%'
        }
    },

    logoContainer: {
        width: '100%'
    },
    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 60,
        width: 280,
        height: 122,
        '@media (max-width: 767px)': {
            width: 210,
            height: 92
        }
    },

    description: {
        marginTop: 20,
        marginBottom: 40,
        paddingLeft: 40,
        paddingRight: 40,
        width: '80%',
        maxWidth: 680,
        marginLeft: 'auto',
        marginRight: 'auto',
        '@media (max-width: 767px)': {
            //textAlign: 'center',
            width: '90%',
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    tagLine: {
        fontSize: 37,
        fontWeight: 500,
        letterSpacing: '1px',
        lineHeight: 1.2,
        color: '#fff',
        fontFamily: 'Questrial'
    },
    subtitle: {
        fontFamily: 'Questrial',
        fontSize: 19,
        fontWeight: 300,
        lineHeight: 1.5,
        paddingTop: 0,
        paddingBottom: 20,
        color: '#fff'
    },

    link: {
        color: '#ccc',
        textDecoration: 'none',
        paddingBottom: 3,
        borderBottom: '1.5px solid rgba(255, 255, 255, 0.25)',
        '&:hover': {
            color: '#fff',
            borderBottom: '1.5px solid rgba(255, 255, 255, 0.5)'
        }
    },

    locations: {
        marginTop: 40,
        textAlign: 'center',
        fontSize: 21,
        fontWeight: 300,
        letterSpacing: 2,
        lineHeight: 1.2,
        color: '#fff',
        fontFamily: 'Questrial',
        textTransform: 'uppercase'
    },
    locationsLink: {
        color: '#fff',
        textDecoration: 'none',
    },
    location: {
        paddingLeft: 2,
        paddingRight: 2
    }
});

export default withStyles(styles)(HomePage);
