// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

import Responsive from 'react-responsive';

//const Desktop = (props: any) => <Responsive {...props} minWidth={992} />;
//const Tablet  = (props: any) => <Responsive {...props} minWidth={768} maxWidth={991} />;
const Mobile  = (props: any) => <Responsive {...props} maxWidth={767} />;
const Default = (props: any) => <Responsive {...props} minWidth={768} />;

export { /*Desktop, Tablet,*/ Mobile, Default };
