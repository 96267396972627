// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

import { Component } from 'react';
import { inject, observer }  from 'mobx-react';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

//import Footer from '../components/Footer';
import ViewStore from '../ViewStore';

interface Props extends WithStyles<typeof styles> {
    viewStore?: ViewStore;
}

const CareersPage = inject('viewStore')(observer(class ContactPage extends Component<Props> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;

        return <>
            <div className={classes.content}>
                <div className={classes.container}>
                    <div className={classes.heading}>
                        Join Our Team
                    </div>
                    <div className={classes.box}>
                        <a className={classes.link} href='/strategist' onClick={this.onStrategistClick}>
                            <p className={classes.role}>Social Media Strategist</p>
                        </a>
                        <a className={classes.link} href='/publicist' onClick={this.onPublicistClick}>
                            <p className={classes.role}>Publicist Contract-to-Hire</p>
                        </a>
                    </div>
                </div>
            </div>
            {/*<Footer />*/}
        </>;
    }

    onStrategistClick = (event: any) => {
        event.preventDefault();
        this.props.viewStore!.showCareer('strategist');
    };

    onPublicistClick = (event: any) => {
        event.preventDefault();
        this.props.viewStore!.showCareer('publicist');
    };
}));

const styles = (theme: Theme) => createStyles({
    content: {
        paddingTop: 60,
        paddingBottom: 60,
        color: '#fff',
        height: '100vh',
        '@media (max-width: 767px)': {
            paddingTop: 40,
            paddingBottom: 40
        }
    },
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80%',
        maxWidth: 800,
        alignItems: 'center',
        '@media (max-width: 767px)': {
            width: '90%',
            paddingLeft: 0,
            paddingRight: 0
        }
    },

    heading: {
        fontSize: 31,
        fontWeight: 500,
        paddingBottom: 16,
        marginBottom: 40,
        marginTop: 60,
        letterSpacing: 4,
        lineHeight: 1.4,
        fontFamily: 'Questrial',
        textTransform: 'uppercase',
        textAlign: 'center'
    },

    box: {
        fontFamily: 'Questrial',
        paddingBottom: 80
    },
    link: {
        color: '#fff',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    hairline: {
        marginTop: 32,
        height: 0.5,
        background: '#ccc'
    },
    role: {
        fontSize: 21,
        fontWeight: 600,
        marginTop: 48,
        marginBottom: 16,
        letterSpacing: 2,
        textTransform: 'uppercase'
    },
    subtitle: {
        fontSize: 19,
        fontWeight: 600,
        marginBottom: 0
    },
    description: {
        marginTop: 6,
        marginBottom: 12,
        fontSize: 19,
        lineHeight: 1.4,
        color: '#ccc'
    },
    list: {
        paddingLeft: 28,
        '& li': {
            lineHeight: 1.5,
            fontSize: 19
        }
    },
    label: { 
        fontSize: 19,
        marginRight: 3,
        lineHeight: 1.5,
        color: '#fff'
    },
    emailAddress: {
        fontSize: 19,
        color: '#fff',
        letterSpacing: 1.1,
        textDecoration: 'none',
        padding: 1,
        borderBottom: '1.5px solid rgba(255, 255, 255, 0.2)',
        '&:hover': {
            textDecoration: 'none',
            borderBottom: '1.5px solid rgba(255, 255, 255, 0.35)'
        }
    }
});

export default withStyles(styles)(CareersPage);
