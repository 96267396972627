// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

import { observer } from 'mobx-react';
import { Component } from 'react';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

//import Footer from '../components/Footer';
//import MapBox from '../components/MapBox';

interface Props extends WithStyles<typeof styles> {
}

const ContactPage = observer(class ContactPage extends Component<Props> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;

        return <>
            <div className={classes.content}>
                <div className={classes.container}>
                    <div className={classes.heading}>
                        Contact Us
                    </div>
                    <div className={classes.box}>
                        <div>
                            <span className={classes.label}>Please email us at </span>
                            <a className={classes.emailAddress} href='mailto:info@ashleyrustassociates.com' target='_blank' rel='noopener noreferrer'>info@ashleyrustassociates.com</a>
                        </div>
                    </div>
                </div>
            </div>
            {/*<Footer />*/}
        </>;
    }
});

const styles = (theme: Theme) => createStyles({
    content: {
        paddingTop: 60,
        paddingBottom: 60,
        color: '#fff',
        height: '100vh',
        '@media (max-width: 767px)': {
            paddingTop: 40,
            paddingBottom: 40
        }
    },
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80%',
        maxWidth: 800,
        alignItems: 'center',
        '@media (max-width: 767px)': {
            width: '100%',
            paddingLeft: 0,
            paddingRight: 0
        }
    },

    heading: {
        fontSize: 31,
        fontWeight: 500,
        paddingBottom: 16,
        marginBottom: 60,
        marginTop: 60,
        letterSpacing: 4,
        lineHeight: 1.4,
        fontFamily: 'Questrial',
        textTransform: 'uppercase',
        textAlign: 'center'
    },

    box: {
        fontFamily: 'Questrial',
        textAlign: 'center'
    },
    link: {
        color: 'rgb(241, 42, 69)'
    },
    location: {
        fontSize: 17,
        fontWeight: 600,
        marginTop: 60,
        marginBottom: 6,
        letterSpacing: 2,
        textTransform: 'uppercase'
    },
    address: {
        margin: 0,
        fontSize: 17,
        lineHeight: 1.5,
        color: '#ccc'
    },
    list: {
        paddingLeft: 28
    },
    label: { 
        fontSize: 19,
        marginRight: 3,
        lineHeight: 1.5,
        color: '#fff'
    },
    emailAddress: {
        fontSize: 19,
        color: '#fff',
        letterSpacing: 0.5,
        textDecoration: 'none',
        paddingBottom: 1,
        borderBottom: '1.5px solid rgba(255, 255, 255, 0.2)',
        '&:hover': {
            textDecoration: 'none',
            borderBottom: '1.5px solid rgba(255, 255, 255, 0.35)'
        }
    }
});

export default withStyles(styles)(ContactPage);
