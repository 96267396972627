// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

import { inject, observer } from 'mobx-react';
import { Component, MouseEvent } from 'react';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

//import Footer from '../components/Footer';
import ViewStore from '../ViewStore';

interface Props extends WithStyles<typeof styles> {
    viewStore?: ViewStore;
}

// TODO add "hidden" link from about page to personal about page for Ashley Rust (goal is to rank in search results)

const AboutPage = inject('viewStore')(observer(class AboutPage extends Component<Props> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;

        return <>
            <div className={classes.content}>
                <div className={classes.container}>
                    <div className={classes.description}>
                        <div className={classes.heading}>About Us</div>
                        <div className={classes.text}>
                            <p>ARA was spearheaded by CEO and founder <a className={classes.link} href='/bio' onClick={this.onBioClick}>Ashley Rust</a> to provide bespoke solutions to complex online issues.</p>
                            <p>At ARA, we invest extensive time and energy into communicating with our clients, understanding their unique needs, and developing the right online solutions. Typically, our digital services involve branding and reputation management, including SEO, ORM, web design, social media, customer engagement, etc. This is why we only cater to a handful of referred clients seeking to improve, enhance, and develop their online reputation and personal brand.</p>
                            <p>Our team is composed of digital strategists, designers, writers, and SEO experts who work individually on various aspects of your project. Our specialists work under NDA with the utmost discretion and have been personally vetted as experts in their field.</p>
                        </div>
                        <div className={classes.linkIcons}>
                            <a className={classes.imageLink} href='https://www.linkedin.com/company/ashleyrustassociates' target='_blank' rel='noopener noreferrer'>
                                <img className={classes.linkIcon} alt='LinkedIn for ARA' src='/images/linkedin_icon.png' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/*<Footer />*/}
        </>;
    }

    onBioClick = (event: MouseEvent) => {
        event.preventDefault();
        this.props.viewStore!.showBio();
    };
}));

const styles = (theme: Theme) => createStyles({
    content: {
        paddingTop: 80,
        paddingBottom: 60,
        '@media (max-width: 767px)': {
            paddingTop: 60,
            paddingBottom: 40
        }
    },
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80%',
        maxWidth: 1000,
        display: 'flex',
        flexDirection: 'column',
        '@media (min-width: 767px)': {
        },
        '@media (max-width: 767px)': {
            alignItems: 'center',
            width: '90%'
        }
    },

    logo: {
        marginTop: 40,
        width: 240,
        '@media (max-width: 767px)': {
            width: 200
        }
    },
    
    description: {
        marginTop: 40,
        marginBottom: 40,
        paddingLeft: 40,
        paddingRight: 40,
        width: '80%',
        maxWidth: 680,
        marginLeft: 'auto',
        marginRight: 'auto',
        '@media (max-width: 767px)': {
            //textAlign: 'center',
            width: '90%',
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    heading: {
        textAlign: 'center',
        fontSize: 31,
        fontWeight: 500,
        letterSpacing: 4,
        color: '#fff',
        fontFamily: 'Questrial',
        textTransform: 'uppercase'
    },
    text: {
        fontSize: 17,
        fontWeight: 300,
        lineHeight: 1.75,
        paddingTop: 60,
        paddingBottom: 20,
        color: '#fff',
        fontFamily: 'Questrial'
    },

    link: {
        color: '#fff',
        textDecoration: 'none',
        paddingBottom: 1,
        borderBottom: '1.5px solid rgba(255, 255, 255, 0.25)',
        '&:hover': {
            color: '#fff',
            borderBottom: '1.5px solid rgba(255, 255, 255, 0.5)'
        }
    },
    imageLink: {
        textDecoration: 'none'
    },

    linkIcons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    linkIcon: {
        border: '1.5px solid rgba(0, 0, 0, 0.75)',
        marginTop: 20,
        width: 32,
        height: 32
    }
});

export default withStyles(styles)(AboutPage);
