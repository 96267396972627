// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

// MenuOverlay appears on mobile when the hamburger menu is opened. It's an opaque background with the Nav menu items in a list.
// The overlay visibility is controlled by ViewStore.menuOpen, which also toggles the visibility of the rest of the app's contents.

import { observer, inject }      from 'mobx-react';
import { Component, MouseEvent } from 'react';

import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';

import ViewStore from '../ViewStore';

interface Props extends WithStyles<typeof styles> {
    viewStore?: ViewStore;
}

const MenuOverlay = inject('viewStore')(observer(class MenuOverlay extends Component<Props> {
    render() {
        const { viewStore, classes } = this.props;

        if (viewStore!.limited) {
            return (
                <div className={classes.root}>
                    <a href='/contact' className={classes.item} onClick={this.onContactClick}>Contact Us</a>
                </div>
            );    
        }

        return (
            <div className={classes.root}>
                <a href='/about' className={classes.item} onClick={this.onAboutClick}>About Us</a>
                <a href='/careers' className={classes.item} onClick={this.onCareersClick}>Careers</a>
                <a href='/contact' className={classes.item} onClick={this.onContactClick}>Contact Us</a>
            </div>
        );
    }

    onAboutClick = (event: MouseEvent) => {
        event.preventDefault();
        this.props.viewStore!.menuOpen = false;
        this.props.viewStore!.showAbout();
    };

    onCareersClick = (event: MouseEvent) => {
        event.preventDefault();
        this.props.viewStore!.menuOpen = false;
        this.props.viewStore!.showCareers();
    };

    onContactClick = (event: MouseEvent) => {
        event.preventDefault();
        this.props.viewStore!.menuOpen = false;
        this.props.viewStore!.showContact();
    };
}));

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'absolute',
        zIndex: 5,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'linear-gradient(28deg, rgba(5, 5, 5, 1.0), rgba(15, 15, 15, 1.0) 90%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 200
    },
    item: {
        flex: 0,
        position: 'relative',
        color: '#fff',
        fontSize: 27,
        fontWeight: 500,
        padding: 20,
        textTransform: 'uppercase',
        fontFamily: 'Questrial',
        letterSpacing: 1.2
    }
});

export default withStyles(styles)(MenuOverlay);
