// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

// @ts-ignore
import director from 'director/build/director';
import { autorun, action, computed, makeObservable, observable } from 'mobx';

import ReactGA from 'react-ga';

interface View {
    name: string;
    title: string;
    role?: string;
}

class ViewStore {
    nodeEnv: string;
    currentView?: View;

    windowSize = { width: window.innerWidth, height: window.innerHeight };

    menuOpen = false;
    limited = false; //true;

    constructor(nodeEnv: string) {
        makeObservable(this, {
            nodeEnv: observable,
            currentView: observable,
            windowSize: observable,
            menuOpen: observable,
            _updateWindowSize: action,
            currentPath: computed,
            homepageUrl: computed,
            showHome: action,
            showAbout: action,
            showBio: action,
            showCareers: action,
            showCareer: action,
            showContact: action
        });

        this.nodeEnv = nodeEnv;

        window.addEventListener('resize', () => this._updateWindowSize());

        if (this.limited) {
            const router = new director.Router({
                '/':        () => this.showHome(),
                '/contact': () => this.showContact()
            });
            router.configure({
                notfound: () => this.showHome(),
                html5history: true
            });
            router.init();
        }
        else {
            const router = new director.Router({
                '/':        () => this.showHome(),
                '/about':   () => this.showAbout(),
                '/bio':     () => this.showBio(),
                '/careers': () => this.showCareers(),
                '/careers/:role': (role: string) => this.showCareer(role),
                '/contact': () => this.showContact()
            });
            router.configure({
                notfound: () => this.showHome(),
                html5history: true
            });
            router.init();
        }
        autorun(() => {
            if (this.currentPath !== window.location.pathname) {
                window.history.pushState(null, '', this.currentPath);

                if (process.env.NODE_ENV === 'production') {
                    ReactGA.pageview(this.currentPath);
                }
            }
        });
    }

    _updateWindowSize() {
        this.windowSize.width  = window.innerWidth;
        this.windowSize.height = window.innerHeight;
    }

    get currentPath(): string {
        const view = this.currentView!;

        if (this.limited) {
            switch (view.name) {
                case 'home':    return `/`;
                case 'contact': return `/contact`;
                default:        return `/`;
            }
        }
        else {
            switch (view.name) {
                case 'home':    return `/`;
                case 'about':   return `/about`;
                case 'bio':     return `/bio`;
                case 'careers': return `/careers`;
                case 'career':  return `/careers/${view.role}`;
                case 'contact': return `/contact`;
                default:        return `/`;
            }
        }
    }

    get homepageUrl() { return this.nodeEnv === 'development' ? 'http://' + window.location.hostname + ':3000' : 'https://ashleyrustassociates.com'; }

    showHome() {
        this.currentView = { name: 'home', title: 'ARA' };
    }

    showAbout() {
        if (this.limited) {
            this.showHome();
            return;
        }
        this.currentView = { name: 'about', title: 'About Us – ARA' };
    }

    showBio() {
        if (this.limited) {
            this.showHome();
            return;
        }
        this.currentView = { name: 'bio', title: 'Ashley Rust – ARA' };
    }

    showCareers() {
        if (this.limited) {
            this.showHome();
            return;
        }
        this.currentView = { name: 'careers', title: 'Join Our Team – ARA' };
    }

    showCareer(role: string) {
        if (this.limited) {
            this.showHome();
            return;
        }
        this.currentView = { name: 'career', title: 'Join Our Team – ARA', role };
    }

    showContact() {
        this.currentView = { name: 'contact', title: 'Contact Us – ARA' };
    }
}

export default ViewStore;
