// Copyright © 2023 Ashley Rust Digital, Inc. All rights reserved.

import { inject, observer } from 'mobx-react';
import { Component, MouseEvent } from 'react';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

//import Footer from '../components/Footer';
import ViewStore from '../ViewStore';

interface Props extends WithStyles<typeof styles> {
    viewStore?: ViewStore;
}

const BioPage = inject('viewStore')(observer(class BioPage extends Component<Props> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;

        return <>
            <div className={classes.content}>
                <div className={classes.container}>
                    <div className={classes.description}>
                        <div className={classes.heading}>Ashley Rust</div>
                        <div className={classes.text}>
                            <p>Founder and CEO of ARA, Ashley Rust, has extensive experience in working with HNW individuals and Fortune 500 companies on their digital image.</p>
                            <p>She first entered the field of online reputation management in 2011 as the Manager, Special Projects for Silicon Valley’s premier ORM firm, Reputation.com. It was there that she honed her ability to build large-scale online initiatives for her clients.</p>
                        </div>
                        <div className={classes.avatars}>
                            <a className={classes.avatarLink} href='https://www.linkedin.com/in/ashleyrust/' target='_blank' rel='noopener noreferrer'>
                                <img className={classes.avatar} src='/images/ashley_headshot.jpeg' alt='Ashley Rust' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/*<Footer />*/}
        </>;
    }

    onContactClick = (event: MouseEvent) => {
        event.preventDefault();
        this.props.viewStore!.showContact();
    };
}));

const styles = (theme: Theme) => createStyles({
    content: {
        paddingTop: 80,
        paddingBottom: 60,
        '@media (max-width: 767px)': {
            paddingTop: 60,
            paddingBottom: 40
        }
    },
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80%',
        maxWidth: 1000,
        display: 'flex',
        flexDirection: 'column',
        '@media (min-width: 767px)': {
        },
        '@media (max-width: 767px)': {
            alignItems: 'center',
            width: '90%'
        }
    },

    logo: {
        marginTop: 40,
        width: 240,
        '@media (max-width: 767px)': {
            width: 200
        }
    },
    
    description: {
        marginTop: 40,
        marginBottom: 40,
        paddingLeft: 40,
        paddingRight: 40,
        width: '80%',
        maxWidth: 680,
        marginLeft: 'auto',
        marginRight: 'auto',
        '@media (max-width: 767px)': {
            //textAlign: 'center',
            width: '90%',
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    heading: {
        textAlign: 'center',
        fontSize: 31,
        fontWeight: 500,
        letterSpacing: 4,
        color: '#fff',
        fontFamily: 'Questrial',
        textTransform: 'uppercase'
    },
    text: {
        fontSize: 17,
        fontWeight: 300,
        lineHeight: 1.75,
        paddingTop: 60,
        paddingBottom: 20,
        color: '#fff',
        fontFamily: 'Questrial'
    },

    link: {
        color: '#ccc',
        textDecoration: 'none',
        paddingBottom: 3,
        borderBottom: '1.5px solid rgba(255, 255, 255, 0.25)',
        '&:hover': {
            color: '#fff',
            borderBottom: '1.5px solid rgba(255, 255, 255, 0.5)'
        }
    },

    avatars: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    avatar: {
        border: '1.5px solid rgba(0, 0, 0, 0.75)',
        marginTop: 40,
        width: 160,
        height: 160,
        borderRadius: 100
    },
    avatarLink: {
        
    }
});

export default withStyles(styles)(BioPage);
